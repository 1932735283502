import React, { PropsWithChildren } from "react"
import { AddToast } from "@/components/AddToast"
import { Sidebar } from "@/components/common/Sidebar"
import { Footer } from "@/components/common/Footer"
import { ToastProvider } from "@/core/store/ToastContext"
import "../../../core/plugins/i18next"

export const Layout: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  return (
    <ToastProvider>
      <div className="bg-[#272b39] lg:mr-[400px] min-h-available">
        <main>{children}</main>
        <Footer />
      </div>
      <Sidebar />
      <AddToast />
    </ToastProvider>
  )
}
