import React from "react"
import { useToast } from "@/core/store/ToastContext"

export type CopyAddressProps = {}

export const CopyAddress: React.FunctionComponent<CopyAddressProps> = () => {
  const { openToast } = useToast()

  const handleCopyUrlClick = async () => {
    const currentUrl = window.location.href
    try {
      await navigator.clipboard.writeText(currentUrl)
      openToast({
        type: "success",
        title: "コピー完了",
        description: "現在のURLをコピーしました",
      })
    } catch (err) {
      openToast({
        type: "error",
        title: "コピー失敗",
        description: "現在のURLをコピーできませんでした",
      })
    }
  }

  return (
    <>
      <button
        className="text-[16px] text-white bg-blue-600 max-lg:w-10 p-3 lg:py-2 lg:px-4 rounded-lg max-lg:aspect-square"
        onClick={handleCopyUrlClick}
      >
        <span className="max-lg:hidden">URLをコピー</span>
        <div className="w-full lg:hidden">
          <img src="/images/ic-copy.svg" className="w-full" alt="" />
        </div>
      </button>
    </>
  )
}
