import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import "@/styles/globals.css"
import "@/core/utils/resizeScreenHeight"
import { RouterMap } from "@/router"
import { Layout } from "@/components/common/layout/Layout"

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <BrowserRouter>
    <Layout>
      <RouterMap />
    </Layout>
  </BrowserRouter>
)
