import { FC, ReactNode, createContext, useState, useContext } from "react"
import * as React from "react"

type ToastContext = {
  type: "success" | "error"
  title: ReactNode
  description?: ReactNode
  isOpen: boolean
  closeToast: () => void
  openToast: ({
    type,
    title,
    description,
  }: {
    type: "success" | "error"
    title: ReactNode
    description?: ReactNode
  }) => void
}

export const ToastContext = createContext<ToastContext | undefined>(undefined)

export const ToastProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [type, setType] = useState<"success" | "error">("success")
  const [title, setTitle] = useState<ReactNode>("")
  const [description, setDescription] = useState<ReactNode>("")

  const openToast = ({
    type,
    title,
    description,
  }: {
    type: "success" | "error"
    title: ReactNode
    description?: ReactNode
  }) => {
    setType(type)
    setTitle(title)
    setDescription(description)
    setOpen(false)
    setTimeout(() => {
      setOpen(true)
    }, 400)
  }

  const closeToast = () => {
    setOpen(false)
  }

  return (
    <ToastContext.Provider
      value={{ type, title, description, isOpen: open, openToast, closeToast }}
    >
      {children}
    </ToastContext.Provider>
  )
}

export const useToast = (): ToastContext => {
  const context = useContext(ToastContext)
  if (context === undefined) {
    throw new Error("useToast must be used within a LoadingProvider")
  }
  return context
}
