import React, { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { CopyAddress } from "@/components/common/CopyAddress"
import { SelectAddress } from "@/components/common/SelectAddress"

export type SidebarProps = {}

export const Sidebar: React.FunctionComponent<SidebarProps> = () => {
  const [NavOpen, setNavOpen] = useState(false)

  const close = useCallback(() => {
    setNavOpen(false)
  }, [])

  useEffect(() => {
    const body = document.body

    if (NavOpen) {
      body.classList.add("overflow-hidden")
    } else {
      body.classList.remove("overflow-hidden")
    }
  }, [NavOpen])

  return (
    <div className="fixed bottom-0 right-0 w-full lg:w-[400px] max-lg:h-20 lg:h-screen max-lg:backdrop-blur-2xl lg:bg-gray-100">
      <div className="h-full px-4 lg:px-6">
        <div className="h-24 h-full flex lg:flex-col justify-between items-center">
          <div
            className={`max-lg:absolute max-lg:bottom-0 max-lg:right-0 max-lg:z-50 w-full h-screen pb-12 pt-20 px-4 max-lg:bg-black/90 max-lg:overflow-y-scroll ${
              NavOpen ? "" : "max-lg:opacity-0 max-lg:invisible"
            }`}
          >
            <ul className="max-lg:text-white">
              <li>
                <Link to="/" className="py-3 inline-block" onClick={close}>
                  Home
                </Link>
              </li>
              <li>
                <a
                  href="mailto:info@nftcard-deck.com"
                  className="py-3 inline-block"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={close}
                >
                  Contact
                </a>
              </li>
              <li>
                <a
                  href={import.meta.env.VITE_SCAN_URL as string}
                  className="py-3 inline-block"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={close}
                >
                  DIVER Scan
                </a>
              </li>
            </ul>
          </div>

          <div className="w-full flex lg:flex-col">
            <CopyAddress />
            <SelectAddress />
            <button
              className="text-[16px] text-white bg-blue-600 max-lg:w-10 p-2 relative z-50 rounded-lg aspect-square lg:hidden"
              onClick={() => setNavOpen(prev => !prev)}
            >
              {NavOpen ? <div>閉</div> : <div>開</div>}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
