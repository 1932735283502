import React from "react"
import { useTranslation } from "react-i18next"

export type FooterProps = {}

export const Footer: React.FunctionComponent<FooterProps> = () => {
  const { t } = useTranslation()
  return (
    <footer className="text-slate-500 max-lg:pb-20 bg-black">
      <div className="py-4 px-6">
        <div className="h-full flex justify-center items-center gap-4">
          <div className="font-medium">
            {t("str_copyright", { company: t("str_web_title") })}
          </div>
        </div>
      </div>
    </footer>
  )
}
