import * as React from "react"
import * as Toast from "@radix-ui/react-toast"
import { useToast } from "@/core/store/ToastContext"
import { useEffect } from "react"

export const AddToast: React.FunctionComponent = () => {
  const { title, description, isOpen, closeToast } = useToast()

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | number

    if (isOpen) {
      timeoutId = setTimeout(() => {
        closeToast()
      }, 3000)
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [isOpen, closeToast])

  return (
    <Toast.Provider swipeDirection="right">
      <Toast.Root
        className="bg-white rounded-md shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] p-[15px] grid [grid-template-areas:_'title_action'_'description_action'] grid-cols-[auto_max-content] gap-x-[15px] items-center data-[state=open]:animate-slideIn data-[state=closed]:animate-hide data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=cancel]:translate-x-0 data-[swipe=cancel]:transition-[transform_200ms_ease-out] data-[swipe=end]:animate-swipeOut"
        open={isOpen}
        onOpenChange={open => {
          if (!open) closeToast()
        }}
      >
        <Toast.Title className="[grid-area:_title] text-black text-[15px] font-medium">
          {title}
        </Toast.Title>
        {description && (
          <Toast.Description className="text-[15px] text-slate-600 font-medium">
            {description}
          </Toast.Description>
        )}
        <Toast.Close className="[grid-area:_action]" asChild>
          <img
            src="/images/ic-close.svg"
            className="w-4 cursor-pointer"
            alt=""
          />
        </Toast.Close>
      </Toast.Root>
      <Toast.Viewport className="[--viewport-padding:_25px] fixed bottom-[60px] lg:bottom-[140px] right-0 flex flex-col p-[var(--viewport-padding)] gap-[10px] w-[390px] max-w-[100vw] m-0 list-none z-[2147483647] outline-none" />
    </Toast.Provider>
  )
}
