import React, { useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import * as Dialog from "@radix-ui/react-dialog"

export const SelectAddress: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [open, setOpen] = useState(false)

  const [address1, setAddress1] = useState<string>(
    searchParams.get("address") || ""
  )
  const [address2, setAddress2] = useState<string>(
    searchParams.get("other_address") || ""
  )

  const handleButtonClick = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (address1) {
      let param = `/?address=${address1}`
      if (address2) {
        param = param.concat(`&other_address=${address2}`)
      }
      navigate(param)
      setOpen(false)
    }
  }

  return (
    <>
      <button
        className="text-[16px] text-white bg-blue-600 max-lg:flex-1 max-lg:mx-3 lg:my-5 py-2 px-4 rounded-lg"
        onClick={() => setOpen(true)}
      >
        アドレスを入力
      </button>

      <Dialog.Root open={open} onOpenChange={setOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 z-20 bg-black/50" />
          <Dialog.Content className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 w-[95vw] max-w-md bg-white rounded-lg p-4 md:w-full">
            <Dialog.Title>アドレス入力</Dialog.Title>
            <Dialog.Description className="text-[14px] mb-5">
              アドレスを最大２つまで入力可能です。
            </Dialog.Description>
            <form onSubmit={event => handleButtonClick(event)}>
              <div className="mb-5">
                <input
                  type="text"
                  value={address1}
                  onChange={e => setAddress1(e.target.value)}
                  className="text-[16px] w-full p-2 border border-black rounded-lg"
                  placeholder="ex. 0x~"
                  required
                />
              </div>
              <div className="mb-5">
                <input
                  type="text"
                  value={address2}
                  onChange={e => setAddress2(e.target.value)}
                  className="text-[16px] w-full p-2 border border-black rounded-lg"
                  placeholder="ex. 0x~ (任意)"
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="text-[14px] text-white bg-black py-2 px-4 rounded-lg inline-flex"
                >
                  保存する
                </button>
              </div>
            </form>
            <Dialog.Close className="absolute top-3.5 right-3.5 inline-flex items-center justify-center rounded-full p-1 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
              <img src="/images/ic-close.svg" className="w-4" alt="" />
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  )
}
